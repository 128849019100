/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
// import ShortcutIcon from "@mui/icons-material/Shortcut";

// import { enqueueSnackbar } from "notistack";
import DocumentsTable from "../../DocumentsTable";

export default function SignedDocuments() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        const filteredSignedDocs = res.data.filter((doc) => {
          const parsedAutoInitials = doc.autoInitials
            ? JSON.parse(doc.autoInitials)
            : [];
          const parsedManualInitials = doc.manualInitials
            ? JSON.parse(doc.manualInitials)
            : [];

          const combinedSignatories = [
            ...parsedAutoInitials,
            ...parsedManualInitials,
          ];

          if (combinedSignatories.some((sign) => sign?.id === auth?.unitId)) {
            return true;
          }

          return false;
        });

        setDocuments(filteredSignedDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const handleRoute = () => {
  //   const confirmed = window.confirm(
  //     "Are you sure you want to move this document to routing documents?"
  //   );

  //   if (confirmed) {
  //     setLoading(true);
  //     setError("");

  //     axiosPrivate
  //       .patch(`/documents/transmitDocs`, {
  //         documents: selectedData,
  //         updateFields: {
  //           status: 8,
  //           remarks: `Moved by ${auth?.firstName} ${auth?.lastName} to routing documents`,
  //         },
  //       })
  //       .then(() => {
  //         setSnackbarMssg("Document Moved Successfully");
  //         setOpenSuccess(true);
  //         setSelectedData(null);
  //         handleGetAll();
  //       })
  //       .catch((err) => {
  //         setError(err?.message);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };

  // const handleTransmit = () => {
  //   const confirmed = window.confirm(
  //     "Are you sure you want to transmit this document?"
  //   );

  //   if (confirmed) {
  //     setLoading(true);
  //     setError("");

  //     let destinations = [];

  //     if (auth.unitId === 1) {
  //       destinations = [{ id: 4, unit: "OSDS - Secretary" }];
  //     } else if (auth.unitId === 2) {
  //       destinations = [{ id: 7, unit: "OASDS - Secretary" }];
  //     }

  //     const destinationNames = destinations.map((dest) => dest.unit);

  //     const destinationsString = destinationNames.join(", ");

  //     axiosPrivate
  //       .patch(`/documents/transmitDocs`, {
  //         documents: selectedData,
  //         updateFields: {
  //           destinations: JSON.stringify(destinations),
  //           lastSource: { id: auth?.unitId, unit: auth?.unitName },
  //           status: 1,
  //           remarks: `Transmitted by ${auth?.firstName} ${auth?.lastName} from ${auth?.unitName} to ${destinationsString}`,
  //         },
  //       })
  //       .then(() => {
  //         enqueueSnackbar("Document Transmitted", {
  //           variant: "success",
  //         });
  //         setSelectedData(null);
  //         handleGetAll();
  //       })
  //       .catch((err) => {
  //         setError(err?.message);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <Box
      sx={{
        overflowX: "auto",
        p: "20px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          Signed Documents
        </Typography>
      </Box>
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            disabled={
              loading ||
              selectedData?.length === 0 ||
              !selectedData ||
              selectedData[0].status === 2 ||
              (selectedData[0].status !== 6 && selectedData[0].status !== 10)
            }
            onClick={() => handleTransmit()}
            sx={{
              backgroundColor:
                loading ||
                selectedData?.length === 0 ||
                !selectedData ||
                selectedData[0].status === 2 ||
                (selectedData[0].status !== 6 && selectedData[0].status !== 10)
                  ? "lightgray"
                  : "#0e6b95",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "5px 20px",
              borderRadius: "15px",
              mr: 2,
              "&:hover": {
                backgroundColor: "lightgray",
                color: "#2f2f2f",
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <ShortcutIcon sx={{ mr: 1 }} />
            Transmit
          </Button>
        </Box> */}

        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          <DocumentsTable
            data={documents}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            showMultipleSelection
            loadingState={loading}
            setLoadingState={setLoading}
            updateTableFunction={handleGetAll}
          />
        </Box>
      </Box>
    </Box>
  );
}
