/* eslint-disable no-param-reassign */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";

import { useEffect, useState } from "react";
import useAxiosPrivate from "contexts/interceptors/axios";
import { enqueueSnackbar } from "notistack";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#f0f0f0",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function UploadSignatureModal({
  open,
  handleClose,
  dataFromActions,
  updateTableFunction,
}) {
  const axiosPrivate = useAxiosPrivate();

  const [rowData, setRowData] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [sign, setSign] = useState();
  const [signError, setSignError] = useState();

  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleUploadSignature = () => {
    setLoading(true);
    setError("");

    const confirm = window.confirm(
      `Are you sure you want to set this as the ${rowData?.firstName}'s signature`
    );

    if (confirm) {
      const formData = new FormData();
      if (sign) {
        formData.append("sign", sign);
      }

      axiosPrivate
        .put(`/user/uploadSignature/${rowData?.uid}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          enqueueSnackbar("User Signature Updated", {
            variant: "success",
          });
          handleClose();
          updateTableFunction();
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSignUpload = async (event) => {
    try {
      setLoading(true);
      setSignError("");

      const file = event.target.files[0];

      if (file && file.type === "image/png") {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
          if (img.height <= 500) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImageUrl(reader.result);
            };
            reader.readAsDataURL(file);

            setSign(file); // Set the file state
            setSignError("");
          } else {
            setSign(null);
            setSignError("Image height must be 500px or less.");
            setImageUrl("");
          }
          URL.revokeObjectURL(objectUrl); // Clean up the object URL
        };

        img.onerror = () => {
          setSignError("Error: Invalid image file.");
          setSign(null);
          URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl; // Set the source to start loading the image
      } else {
        setSignError("Error: Only PNG files are accepted.");
        setSign(null);
      }
    } catch (err) {
      setSignError("Error: Invalid PNG file.");
      setSign(null);
      console.error(err); // Log the error
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  useEffect(() => {
    setDisabled(!sign);
  }, [sign]);

  useEffect(() => {
    if (dataFromActions) {
      if (Array.isArray(dataFromActions) && dataFromActions.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        setRowData(dataFromActions[0]);
      } else {
        setRowData(dataFromActions);
      }
    }
  }, [dataFromActions]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {loading && (
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box sx={style}>
          <Box>
            <Box // Fixed Header
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#59395c",
                // boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderBottomLeftRadius: "30%",
                borderBottomRightRadius: "200px",
                zIndex: 2,
                px: 4,
                mr: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    color: "#fff",
                    py: 1,
                  }}
                >
                  Upload Signature
                </Typography>
                <IconButton
                  onClick={handleClose}
                  sx={{ color: "#fff", p: 0, m: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            </Box>
            {error && (
              <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                m: 4,
              }}
            >
              {signError && (
                <Box sx={{ backgroundColor: "red", width: "100%", px: 1 }}>
                  <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                    {signError}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  width: "100%",
                  gap: 2,
                  mb: "2.5%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                    width: "200px",
                    backgroundColor: "#fff",
                    border: "solid 1px gray",
                    mr: 2,
                    mb: 2,
                  }}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="signature"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    <p>No image available</p>
                  )}
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      background: "#ebebeb",
                      border: "solid 1px #b6b6b6",
                      borderRadius: "4px",
                      width: "100%",
                      mb: 2,
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "gray",
                        mr: 2,
                      }}
                    >
                      Upload Signature:
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                        mt: 2,
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "4px",
                          border: "solid 1px #b6b6b6",
                          width: "25vw",
                          minWidth: "200px",
                          color: sign ? "black" : "#757575",
                          py: "8px",
                          px: "12px",
                          mr: 2,
                        }}
                      >
                        <Typography>
                          {sign ? sign?.name : "No PNG chosen"}
                        </Typography>
                      </Box>
                      <Button
                        htmlFor={loading ? null : "docUpload"}
                        sx={{
                          backgroundColor: "#2f2f2f",
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: "bold",
                          padding: "5px 20px",
                          borderRadius: "5px",
                          "&:hover": {
                            boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                            backgroundColor: "#f6e247",
                            color: "#59395c",
                            fontWeight: "bold",
                          },
                        }}
                      >
                        <Typography
                          variant="label"
                          component="label"
                          htmlFor={loading ? null : "docUpload"}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            fontSize: "small",
                          }}
                        >
                          <UploadIcon sx={{ mr: 1 }} />
                          CHOOSE PNG
                        </Typography>
                        <input
                          id="docUpload"
                          type="file"
                          name="doc_upload"
                          onChange={handleSignUpload}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 2,
              zIndex: 10,
            }}
          >
            <Button
              disabled={disabled}
              onClick={() => handleUploadSignature()}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: disabled ? "lightgray" : "#59395c",
                color: "#fff",
                py: 1,
                width: "10vw",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor: "#f6e247",
                  color: "#59395c",
                  fontWeight: "bold",
                },
              }}
            >
              <UploadIcon
                sx={{
                  mr: 1,
                }}
              />
              Upload
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
