/* eslint-disable no-alert */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Close";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import useAxiosPrivate from "contexts/interceptors/axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useStateContext } from "contexts/ContextProvider";
import { enqueueSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#da2c43",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  height: "60vh",
  width: "65vw",
  minHeight: "300px",
  minWidth: "500px",
};

export default function ReturnDocumentModal({
  open,
  handleClose,
  updateTableFunction,
  loadingState,
  selectedData,
}) {
  const { auth } = useStateContext();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [disabled, setDisabled] = useState(false);
  const [destination, setDestination] = useState();
  const [selected, setSelected] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      annotation: "",
    },

    validationSchema: object().shape({
      annotation: string(),
    }),
    onSubmit: (values) => {
      const confirmed = window.confirm(
        "Are you sure you want to return this document?"
      );

      if (confirmed) {
        setLoading(true);
        setError("");

        // const formattedDestination = {
        //   ...destination,
        //   unit: destination.unitName,
        // };

        axiosPrivate
          .put(`/documents/returnDocument`, {
            docuId: selected?.id,
            fileId: selected?.fileId,
            destinations: JSON.stringify([destination]),
            currentOwner: destination && destination?.id,
            annotation: {
              annotation: `Returned: ${values.annotation}`,
              annotatedBy: `${auth?.firstName} ${auth?.lastName} from ${auth?.unitName}`,
            },
            remarks: `Returned by ${auth?.firstName} ${auth?.lastName} from ${auth?.unitName}`,
          })
          .then(() => {
            enqueueSnackbar("Document Returned", {
              variant: "default",
            });
            updateTableFunction();
            handleClose();
          })
          .catch((err) => {
            setError(err.response.data.error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  useEffect(() => {
    let rowData;
    let lastSource;

    if (selectedData) {
      if (Array.isArray(selectedData) && selectedData.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        rowData = selectedData[0];
      } else {
        rowData = selectedData;
      }

      if (selectedData.length === 0) {
        rowData = null;
      }
    }

    if (rowData) {
      const lastSources = JSON.parse(rowData?.lastSource);

      if (lastSources.length === 1) {
        lastSource = lastSources ? lastSources[0] : null;
      } else if (
        auth?.role === "secretary" &&
        (location.pathname === "/incoming" ||
          location.pathname === "/incomingDocs")
      ) {
        lastSource = lastSources ? lastSources[1] : null;
      } else {
        lastSource = lastSources ? lastSources[1] : null;
      }

      setDestination(lastSource);
      setSelected(rowData);
    }
  }, [selectedData]);

  useEffect(() => {
    setDisabled(formik.values.annotation);
  }, [formik.values]);

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {loading && (
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          variant="form"
          component="form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          sx={style}
        >
          <Box>
            <Box // Fixed Header
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#660d0a",
                // boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderBottomLeftRadius: "30%",
                borderBottomRightRadius: "200px",
                zIndex: 2,
                px: 4,
                mr: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    color: "#fff",
                    py: 1,
                  }}
                >
                  Return Document
                </Typography>
                <IconButton
                  onClick={handleClose}
                  sx={{ color: "#fff", p: 0, m: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
              {/* <Divider sx={{ backgroundColor: "gray" }} /> */}
            </Box>
            <Box
              sx={{
                backgroundColor: "#660d0a",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
                p: 2,
                mx: 4,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                }}
              >
                {`Returning to ${destination?.unit}`}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
                p: 2,
                mx: 4,
              }}
            >
              {error && (
                <Box
                  sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}
                >
                  <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                    {error}
                  </Typography>
                </Box>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Annotation"
                    placeholder="State reason for returning the document..."
                    name="annotation"
                    variant="outlined"
                    disabled={loading}
                    value={formik.values.annotation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBLur}
                    error={
                      formik.touched.annotation &&
                      Boolean(formik.errors.annotation)
                    }
                    helperText={
                      formik.touched.annotation && formik.errors.annotation
                    }
                    multiline
                    rows={4}
                    sx={{
                      width: "100%",
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 2,
              zIndex: 10,
              mt: 2,
            }}
          >
            <Button
              disabled={!disabled}
              type="submit"
              sx={{
                display: "flex",
                alignItems: "center",
                // backgroundColor: disabled ? "#660d0a" : "#59395c",
                backgroundColor: "#660d0a",
                color: "#fff",
                py: 1,
                px: 2,
                // width: "10vw",
                // minWidth: "100px",
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "red",
                  fontWeight: "bold",
                },
              }}
            >
              <KeyboardReturnIcon
                sx={{
                  mr: 1,
                }}
              />
              Return
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
