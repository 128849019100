import { Box, Typography } from "@mui/material";

function StatBox({ title, subtitle, icon }) {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {icon}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: subtitle.length > 15 ? "15px" : "18px",
              color: "gray",
              fontWeight: "bold",
              "@media (max-width: 1520px)": {
                fontSize: subtitle.length > 15 ? "13px" : "15px",
              },
            }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{
              color: "#494949",
              "@media (max-width: 1520px)": {
                fontSize: "30px",
              },
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default StatBox;
