/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";

import TurnLeftIcon from "@mui/icons-material/TurnLeft";

import { enqueueSnackbar } from "notistack";
import DocumentsTable from "../../DocumentsTable";

export default function OnHoldDocuments() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        const filteredOnholdDocs = res.data.filter(
          (doc) => doc.status === 5 && doc.currentOwner === auth.unitId
        );

        setDocuments(filteredOnholdDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  const handleReRoute = () => {
    const confirmed = window.confirm(
      "Are you sure you want to re-route this document?"
    );

    if (confirmed) {
      setLoading(true);
      setError("");

      axiosPrivate
        .put(`/documents/rerouteDocuments`, {
          documents: selectedData,
          remarks: `Re-routed by ${auth?.firstName} ${auth?.lastName} from ${auth?.unitName}`,
        })
        .then(() => {
          enqueueSnackbar(
            `Document${selectedData.length > 1 ? "s" : ""} Re-routed`,
            {
              variant: "success",
            }
          );
          handleGetAll();
        })
        .catch((err) => {
          setError(err.response.data.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setSelectedData(null);
  };

  return (
    <Box
      sx={{
        overflowX: "auto",
        p: "20px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          On-Hold Documents
        </Typography>
      </Box>
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            mt: -0.5,
            minWidth: "30%",
            right: 280,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={loading || selectedData?.length === 0 || !selectedData}
              onClick={() => handleReRoute()}
              sx={{
                backgroundColor:
                  loading || selectedData?.length === 0 || !selectedData
                    ? "lightgray"
                    : "#184c8c",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <TurnLeftIcon sx={{ mr: 1 }} />
              Re-route
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          <DocumentsTable
            data={documents}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            showCheckbox
            showMultipleSelection
            loadingState={loading}
            setLoadingState={setLoading}
            updateTableFunction={handleGetAll}
          />
        </Box>
      </Box>
    </Box>
  );
}
