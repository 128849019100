/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import CheckIcon from "@mui/icons-material/Check";

import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import ReturnDocumentModal from "modals/documents/ReturnDocumentModal";
import { enqueueSnackbar } from "notistack";
import DocumentsTable from "../../DocumentsTable";

export default function ApprovalDocuments() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openReturnModal, setOpenReturnModal] = useState(false);

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredRoutedDocs = res?.data?.filter((doc) => {
          if (
            doc.currentOwner !== auth.unitId &&
            doc.status === 8 &&
            doc.acceptStatus === 0
          ) {
            return true;
          }
          return false;
        });

        setDocuments(filteredRoutedDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  const handleRoute = () => {
    const confirmed = window.confirm(
      `Are you sure you want to route ${
        selectedData?.length > 1 ? "these" : "this"
      } ${selectedData?.length > 1 ? "documents" : "document"}?`
    );

    if (confirmed) {
      setLoading(true);
      setError("");

      axiosPrivate
        .patch(`/documents/transmitDocs`, {
          documents: selectedData,
          updateFields: {
            lastSource: { id: auth?.unitId, unit: auth?.unitName },
            // eslint-disable-next-line no-nested-ternary
            currentOwner: SDSSecIds.includes(auth?.unitId)
              ? 4
              : ASDSSecIds.includes(auth?.unitId)
              ? 7
              : auth?.unitId,
            status: 9,
            remarks: `Routed by ${auth?.firstName} ${auth?.lastName} from ${auth?.unitName}`,
          },
        })
        .then(() => {
          enqueueSnackbar(
            `Document${selectedData?.length > 1 ? "s" : ""} Routed`,
            {
              variant: "success",
            }
          );
          handleGetAll();
        })
        .catch((err) => {
          setError(err.response.data.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setSelectedData(null);
  };

  // const handleReturn = () => {
  //   let rowData;

  //   if (selectedData) {
  //     if (Array.isArray(selectedData) && selectedData.length > 0) {
  //       // eslint-disable-next-line prefer-destructuring
  //       rowData = selectedData[0];
  //     } else {
  //       rowData = selectedData;
  //     }
  //   }

  //   // setSelectedData(rowData);
  //   // setOpenReturnModal(true);

  //   const confirmed = window.confirm(
  //     "Are you sure you want to return this document?"
  //   );

  //   if (confirmed) {
  //     setLoading(true);
  //     setError("");

  //     const lastSources = JSON.parse(rowData?.lastSource);

  //     let lastSource;

  //     if (lastSources.length === 1) {
  //       lastSource = lastSources ? lastSources[0] : null;
  //     } else {
  //       lastSource = lastSources ? lastSources[1] : null;
  //     }

  //     axiosPrivate
  //       .patch(`/documents/transmitDocs`, {
  //         documents: selectedData,
  //         updateFields: {
  //           destinations: JSON.stringify([lastSource]),
  //           lastSource: { id: auth?.unitId, unit: auth?.unitName },
  //           status: 8,
  //           remarks: `Returned by ${auth?.firstName} ${auth?.lastName} from ${auth?.unitName} to ${rowData?.lastSource}`,
  //         },
  //       })
  //       .then(() => {
  //         setSnackbarMssg("Document Returned Successfully");
  //         setOpenSuccess(true);
  //         handleGetAll();
  //         // setOpenViewModal(false);
  //         setOpenSuccess(true);
  //       })
  //       .catch((err) => {
  //         setError(err?.message);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };

  // font "Mont"

  return (
    <Box
      sx={{
        overflowX: "auto",
        p: "20px",
        width: "100%",
      }}
    >
      <ReturnDocumentModal
        open={openReturnModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenReturnModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          Documents for Routing
        </Typography>
      </Box>
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            mt: -0.5,
            minWidth: "30%",
            right: 280,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                !selectedData ||
                selectedData[0]?.status === 2
              }
              onClick={() => handleRoute()}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  !selectedData ||
                  selectedData[0]?.status === 2
                    ? "lightgray"
                    : "green",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <CheckIcon sx={{ mr: 1 }} />
              Approve
            </Button>
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                selectedData?.length > 1 ||
                !selectedData ||
                selectedData[0]?.status === 2
              }
              onClick={() => setOpenReturnModal(true)}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  selectedData?.length > 1 ||
                  !selectedData ||
                  selectedData[0]?.status === 2
                    ? "lightgray"
                    : "#da2c43",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <KeyboardReturnIcon sx={{ mr: 1 }} />
              Return
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          <DocumentsTable
            data={documents}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            showCheckbox
            showMultipleSelection
            loadingState={loading}
            setLoadingState={setLoading}
            updateTableFunction={handleGetAll}
          />
        </Box>
      </Box>
    </Box>
  );
}
