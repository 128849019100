import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import dayjs from "dayjs";

export default function DetailsModal({ actions, annotations, setOpenDetails }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "Center",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        height: "100%",
        width: "100%",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          height: "90%",
          width: "90%",
          backgroundColor: "#fff",
          overflow: "auto",
          p: 2,
          borderRadius: "10px",
          boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.4)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            zIndex: 100,
            py: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "25px",
            }}
          >
            Document Details
          </Typography>
          <IconButton onClick={() => setOpenDetails(false)}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "block",
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "block",
              background: "#ebebeb",
              border: "solid 1px #b6b6b6",
              borderRadius: "4px",
              width: "100%",
              p: 2,
            }}
          >
            <Box sx={{ mb: 4 }}>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                Actions:
              </Typography>
              {actions?.action?.map((action) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    ml: 2,
                  }}
                >
                  <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                  {action}
                </Box>
              ))}
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "gray",
                  mt: 2,
                }}
              >
                Prepare:
              </Typography>
              {actions?.prepare?.map((req) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    ml: 2,
                  }}
                >
                  <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                  {req}
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "block",
              background: "#ebebeb",
              border: "solid 1px #b6b6b6",
              borderRadius: "4px",
              width: "100%",
              mt: 4,
              p: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              Annotations:
            </Typography>
            {annotations?.map((e) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  px: 2,
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
                <Box sx={{ p: 2 }}>
                  <Typography>{e?.annotation}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "gray",
                        mr: 1,
                      }}
                    >
                      Annotated By:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "gray",
                      }}
                    >
                      {e?.annotatedBy}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "gray",
                        mr: 1,
                      }}
                    >
                      Date:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "gray",
                      }}
                    >
                      {` ${dayjs(e?.dateOfAnnotation).format(
                        "MM/DD/YYYY - hh:mm A"
                      )}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
