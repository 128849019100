import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Outlet, useLocation } from "react-router-dom";
import { Box, CssBaseline, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DocLogsModal from "modals/documents/DoclogsModal";
import Topbar from "../components/Topbar/AdminTopBar";
import Sidebar from "../components/Sidebar";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function AdminLayout() {
  const location = useLocation();
  const [showSearch, setShowSearch] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: location.pathname === "/form" ? "#e5bfa1" : "#f0f0f0",
        height: "100vh",
        // overflow: "auto",
        // width: "100vw",
      }}
    >
      <DocLogsModal
        open={openSearchModal}
        handleClose={() => setOpenSearchModal(false)}
      />
      <CssBaseline />
      <Sidebar
        themeProp={theme}
        drawerWidth={drawerWidth}
        drawerOpenStatus={open}
        closeDrawerFunction={handleDrawerClose}
      />
      <Topbar
        themeProp={theme}
        drawerWidth={drawerWidth}
        openDrawerFunction={handleDrawerOpen}
        drawerOpenStatus={open}
      />

      <Box
        component="main"
        sx={{
          display: "block",
          transition: "ease-in-out 0.1s",
          width: `calc(100% - 57px)`,
          minWidth: "360px",
          // overflowX: "auto",
          // width: "100%",
          // p: 3,
          // backgroundColor:
          //   location.pathname === "/form" ? "#e5bfa1" : "#f0f0f0",
          mt: 2,
          "@media (min-height: 1920px)": {
            mt: 6,
          },

          ml: open ? 26 : 3,
          // [theme.breakpoints.up("sm")]: {
          //   ml: open ? 26 : 4,
          // },
          "@media (max-width: 380px)": {
            ml: 0,
          },
        }}
      >
        <DrawerHeader theme={theme} />
        <Outlet />
      </Box>

      <Box
        onMouseEnter={() => setShowSearch(true)}
        sx={{
          // border: "solid 1px red",
          width: "10px",
          height: "20%",
          position: "fixed",
          zIndex: 1500,
          right: 0,
          bottom: 0,
        }}
      />
      <Box
        onMouseLeave={() => setShowSearch(false)}
        sx={{
          position: "fixed",
          // border: "solid 1px black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "start",
          pb: 6,
          pl: 2,
          overflow: "hidden",
          height: "20%",
          transition: "transform 0.3s ease-in-out",
          width: "130px", // Fixed width for the sliding box
          transform: showSearch ? "translateX(0)" : "translateX(100%)",
          bottom: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <IconButton
          onClick={() => setOpenSearchModal(true)}
          sx={{
            backgroundColor: "lightgray",
            boxShadow: "2px 1px 15px 5px lightgray",
            border: "solid 1px gray",
            p: 1.5,
            zIndex: 100,
            transition: "1s ease-in-out",
            "&:hover": {
              backgroundColor: "#fff",
              border: "none",
              boxShadow: "0 -1px 10px 5px #a4a4a4",
            },
          }}
        >
          <SearchIcon sx={{ fontSize: "30px" }} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default AdminLayout;
