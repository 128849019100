import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import { useNavigate } from "react-router-dom";
import DepedLogo from "../../assets/images/deped_logo.png";
import ProfileMenu from "../DropDownMenus/ProfileMenu";

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, width }) => ({
  // background: "linear-gradient(180deg, #6e3c72, #59395c, #472a4a)",
  // background: "linear-gradient(180deg, #e8ac20, #f6e247, #b38a29)",
  background: "linear-gradient(180deg, #d2d2d2, #cecece, #b2b2b2)",
  // background:
  //   "linear-gradient(40deg, rgba(66, 201, 116, 1), rgba(59, 152, 184, 1), rgba(26, 214, 164, 1))",
  // "linear-gradient(40deg, rgba(56, 56, 56, 1), rgba(80, 80, 80, 1), rgba(105, 105, 105, 0.9))",
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: width,
    width: `calc(100% - ${width}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Topbar({
  themeProp,
  drawerWidth,
  openDrawerFunction,
  drawerOpenStatus,
}) {
  // const navigate = useNavigate();

  // const handleNavigate = () => {
  //   navigate("/");
  // };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
    >
      <StyledAppBar
        position="fixed"
        theme={themeProp}
        open={drawerOpenStatus}
        width={drawerWidth}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={openDrawerFunction}
              edge="start"
              sx={{
                color: "#59395c",
                marginRight: 5,
                ...(drawerOpenStatus && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              // onClick={handleNavigate}
              component="img"
              variant="img"
              src={DepedLogo}
              sx={{
                mr: 2,
                my: 1,
                width: "60px",
                "@media (min-height: 1920px)": {
                  width: "100px",
                },
              }}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ color: "#59395c", fontWeight: "bold" }}
            >
              SDO Imus - Less Paper System
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mr: 5,
            }}
          >
            <IconButton
              // onClick={() => handleNavigate()}
              sx={{
                color: "#59395c",
                mr: 1,
                // "&:hover": {
                //   color: "gray",
                // },
              }}
            >
              <NotificationsIcon
                sx={{
                  fontSize: 30,
                }}
              />
            </IconButton>
            <ProfileMenu />
          </Box>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}

export default Topbar;
