/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import UploadIcon from "@mui/icons-material/Upload";

import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import AttachDocumentModal from "modals/documents/AttachDocument";
import DocumentsTable from "../../DocumentsTable";

export default function RoutedDocuments() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openAttachDocModal, setOpenAttachDocModal] = useState(false);

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredRoutedDocs = res?.data?.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          if (
            doc.status === 9 &&
            destinations.some((dest) => dest.id === auth.unitId)
          ) {
            return true;
          }
          return false;
        });

        setDocuments(filteredRoutedDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <Box
      sx={{
        overflowX: "auto",
        p: "20px",
        width: "100%",
      }}
    >
      <AttachDocumentModal
        open={openAttachDocModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenAttachDocModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          Routed Documents
        </Typography>
      </Box>
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            mt: -0.5,
            minWidth: "30%",
            right: 280,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                !selectedData ||
                selectedData[0]?.status === 2
              }
              onClick={() => setOpenAttachDocModal(true)}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  !selectedData ||
                  selectedData[0]?.status === 2
                    ? "lightgray"
                    : "green",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <UploadIcon sx={{ mr: 1 }} />
              Attach a document
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          <DocumentsTable
            data={documents}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            showCheckbox
            showMultipleSelection
            loadingState={loading}
            setLoadingState={setLoading}
            updateTableFunction={handleGetAll}
          />
        </Box>
      </Box>
    </Box>
  );
}
