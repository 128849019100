/* eslint-disable no-plusplus */
// import { useEffect } from "react";
// import { useStateContext } from "contexts/ContextProvider";
import axios from "axios";
// import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
  // const refresh = useRefreshToken();
  // const { auth, setSessionExpired } = useStateContext();
  // const BASE_URL = "http://localhost:9000";
  // const BASE_URL = "http://172.16.0.27:8000";
  const BASE_URL = "https://lesspaper.depedimuscity.com:8000";

  const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });

  // useEffect(() => {
  //   const requestIntercept = axiosPrivate.interceptors.request.use(
  //     (config) => {
  //       if (!config.headers.Authorization) {
  //         // eslint-disable-next-line no-param-reassign
  //         config.headers.Authorization = `Bearer ${auth?.accessToken}`;
  //       }
  //       return config;
  //     },
  //     (error) => Promise.reject(error)
  //   );

  //   // let alertShown = false;

  //   const responseIntercept = axiosPrivate.interceptors.response.use(
  //     (response) => response,
  //     async (error) => {
  //       const prevRequest = error?.config;

  //       // if (sessionExpired && !alertShown) {
  //       //   alert("Session has expired. Please log in again");
  //       //   setAuth(null);
  //       //   alertShown = true; // Set flag to true
  //       // }

  //       if (error?.response?.status === 403 && !prevRequest?.sent) {
  //         prevRequest.sent = true;
  //         const newAccessToken = await refresh();
  //         if (!newAccessToken) {
  //           setSessionExpired(true);
  //           return Promise.reject(error);
  //         }
  //         prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
  //         return axiosPrivate(prevRequest);
  //       }
  //       return Promise.reject(error);
  //     }
  //   );

  //   return () => {
  //     axiosPrivate.interceptors.request.eject(requestIntercept);
  //     axiosPrivate.interceptors.response.eject(responseIntercept);
  //   };
  // }, [auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
