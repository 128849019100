/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import StatBox from "components/StatBox";

export default function ResponsiveCards({ contents, loadingState }) {
  const navigate = useNavigate();

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const chunkedContents = chunkArray(contents, 2);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "4vw",
      }}
    >
      {chunkedContents.map((chunk) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "4vw",
          }}
        >
          {chunk.map((content) => (
            <Box
              onClick={() => {
                navigate(content.path);
              }}
              sx={{
                backgroundColor: "#cccccc",
                boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                borderRadius: "20px",
                width: "18vw",
                minWidth: "290px",
                cursor: "pointer",
                transition: "all .5s",
                p: 2,
                "&:hover": {
                  boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                  backgroundColor: "#ededed",
                },
                "@media (max-width: 1550px)": {
                  // width: "18vw",
                  minWidth: "230px",
                  // maxWidth: "350px",
                },
              }}
            >
              <StatBox
                title={loadingState ? <CircularProgress /> : content.value}
                subtitle={content.title}
                icon={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // background: "linear-gradient(120deg, #27bf13, #207a14)",
                      background: content.iconColor,
                      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                      mr: 2,
                      p: 2,
                      borderRadius: "50%",
                    }}
                  >
                    {content.icon}
                  </Box>
                }
              />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}
