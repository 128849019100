/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Box, Tooltip, Typography } from "@mui/material";

import { PDFDocument } from "pdf-lib"; // or use 'pdfjs-dist'

// Icons
import { AiFillSignature } from "react-icons/ai";
import ChecklistIcon from "@mui/icons-material/Checklist";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { FaRegCircleCheck } from "react-icons/fa6";
import { TbSignatureOff } from "react-icons/tb";
import VisibilityIcon from "@mui/icons-material/Visibility";

import EditableTable from "components/Table/EditableTable";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useAxiosPrivate from "contexts/interceptors/axios";
import ViewDocumentModal from "modals/documents/ViewDocumentModal";
import ViewDetailsModal from "modals/documents/ViewDetailsModal";
import { useStateContext } from "contexts/ContextProvider";
import ManualSignModal from "modals/documents/SigningModals/ManualSignModal";
import AutoSignModal from "modals/documents/SigningModals/AutoSignModal";
import { enqueueSnackbar } from "notistack";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DocumentsTable({
  data,
  selectedData,
  setSelectedData = () => {},
  singleSelect = false,
  showCheckbox = false,
  showMultipleSelection = false,
  loadingState,
  setLoadingState,
  updateTableFunction,
}) {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const [openViewModal, setOpenViewModal] = useState(false);
  const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);
  const [openSetSignPositionModal, setOpenSetSignPositionModal] =
    useState(false);
  const [openAutoSignModal, setOpenAutoSignModal] = useState(false);

  const [pdfUrl, setPdfUrl] = useState();

  const [dataFromActions, setDataFromActions] = useState();

  const [loading, setLoading] = useState(loadingState);

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const handleManualSigning = async () => {
    setLoading(true);

    const { fileId } = dataFromActions;

    await axiosPrivate
      .get(`/documents/getDriveFileInfo`, {
        params: { fileId },
        responseType: "blob",
      })
      .then(async (res) => {
        const url = window.URL.createObjectURL(
          new Blob([res?.data], { type: "application/pdf" })
        );

        // Convert Blob to ArrayBuffer
        const arrayBuffer = await res.data.arrayBuffer();

        // Load the PDF document using pdf-lib
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const numberOfPages = pdfDoc.getPageCount();

        setPdfUrl(url);
        setDataFromActions({ ...dataFromActions, numberOfPages });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setOpenSetSignPositionModal(true);
      });
  };

  const handleAutoDetectSign = async () => {
    setLoading(true);

    const { fileId } = dataFromActions;

    await axiosPrivate
      .get(`/documents/getDriveFileInfo`, {
        params: { fileId },
        responseType: "blob",
      })
      .then(async (res) => {
        const url = window.URL.createObjectURL(
          new Blob([res?.data], { type: "application/pdf" })
        );

        // Convert Blob to ArrayBuffer
        const arrayBuffer = await res.data.arrayBuffer();

        // Load the PDF document using pdf-lib
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const numberOfPages = pdfDoc.getPageCount();

        setPdfUrl(url);
        setDataFromActions({ ...dataFromActions, numberOfPages });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setOpenAutoSignModal(true);
      });
  };

  const handleViewFile = async (rowData) => {
    setLoading(true);

    const { fileId } = rowData;

    await axiosPrivate
      .get(`/documents/getDriveFileInfo`, {
        params: { fileId },
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res?.data], { type: "application/pdf" })
        );
        setPdfUrl(url);
        setOpenViewModal(true);
        setDataFromActions(rowData);
      })
      .catch((err) => {
        console.log(err?.message);
        enqueueSnackbar("File not found in the drive", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleViewDetails = async (rowData) => {
    if (rowData) {
      setDataFromActions(rowData);
      setOpenViewDetailsModal(true);
    }
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      getActions: (params) => {
        const actionItems = [];

        actionItems.push(
          <Tooltip
            key="view"
            title={
              location.pathname === "/unsigned" ||
              location.pathname === "/signature"
                ? "Sign"
                : location.pathname === "/signed"
                ? "Unsign"
                : "View"
            }
            placement="top"
          >
            <GridActionsCellItem
              icon={
                location.pathname === "/unsigned" ||
                location.pathname === "/signature" ? (
                  <AiFillSignature style={{ fontSize: "19px" }} />
                ) : location.pathname === "/signed" ? (
                  <TbSignatureOff style={{ fontSize: "19px" }} />
                ) : (
                  <VisibilityIcon />
                )
              }
              label="View"
              onClick={() => handleViewFile(params.row)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip key="viewDetails" title="View Details" placement="top">
            <GridActionsCellItem
              icon={<ChecklistIcon />}
              label="View Details"
              onClick={() => handleViewDetails(params.row)}
              color="inherit"
            />
          </Tooltip>
        );

        return actionItems;
      },
    },
    { field: "id", headerName: "ID", width: 70 },
    { field: "docNo", headerName: "LPS. No.", width: 120 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const {
          primSourceId,
          lastSource,
          destinations,
          acceptStatus,
          createdAtDateTime,
        } = params.row;

        const parsedLastSources = JSON.parse(lastSource);
        const parsedDestinations = JSON.parse(destinations);

        let chosenLastSource;

        if (parsedLastSources.length === 1) {
          chosenLastSource = parsedLastSources ? parsedLastSources[0] : null;
        } else {
          chosenLastSource = parsedLastSources ? parsedLastSources[1] : null;
        }

        const currentDateTime = new Date();
        const createdAtDateTimeObj = new Date(createdAtDateTime);
        // const lastUpdateDateTimeObj = new Date(lastUpdateDateTime);

        const fifteenDaysInMilliseconds = 15 * 24 * 60 * 60 * 1000;

        if (params.value === 1) {
          if (
            parsedDestinations.some(
              (dest) =>
                dest.id === chosenLastSource.id || dest.id === auth?.unitId
            )
          ) {
            return "Incoming";
          } else if (
            primSourceId === chosenLastSource.id ||
            chosenLastSource.id === auth.unitId ||
            location.pathname === "/outgoing" ||
            location.pathname === "/outgoingDocs"
          ) {
            return "Outgoing";
          } else {
            return "Incoming";
          }

          // if (docNo === "090924-002") {
          //   return "Incoming";
          // } else {
          //   return "Outgoing";
          // }
        } else if (params.value === 2) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              Returned
            </Typography>
          );
        } else if (params.value === 3) {
          if (createdAtDateTime) {
            if (
              currentDateTime.getTime() >=
              createdAtDateTimeObj.getTime() + fifteenDaysInMilliseconds
            ) {
              return (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  Lapsed
                </Typography>
              );
            }
          }

          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "green",
              }}
            >
              Accepted
            </Typography>
          );
        } else if (params.value === 4) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#0d85ee",
              }}
            >
              Saved
            </Typography>
          );
        } else if (params.value === 5) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#c49019",
              }}
            >
              On-Hold
            </Typography>
          );
        } else if (params.value === 6) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "green",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  mr: 1,
                }}
              >
                Signed
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <FaRegCircleCheck />
              </Box>
            </Box>
          );
        } else if (params.value === 7) {
          if (acceptStatus === 1) {
            return (
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Unsigned
              </Typography>
            );
          } else if (
            parsedDestinations.some((dest) => dest.id === chosenLastSource.id)
          ) {
            return "Incoming";
          } else if (
            primSourceId === chosenLastSource.id ||
            chosenLastSource.id === auth.unitId
          ) {
            return "Outgoing";
          } else {
            return "Incoming";
          }
        } else if (params.value === 8) {
          if (acceptStatus === 1) {
            return (
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#c49019",
                }}
              >
                Routing
              </Typography>
            );
          } else if (
            parsedDestinations.some((dest) => dest.id === chosenLastSource.id)
          ) {
            return "Incoming";
          } else if (
            primSourceId === chosenLastSource.id ||
            chosenLastSource.id === auth.unitId
          ) {
            return "Outgoing";
          } else {
            return "Incoming";
          }
        } else if (params.value === 9) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#246fc9",
              }}
            >
              Routed
            </Typography>
          );
        } else if (params.value === 10) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#656791",
              }}
            >
              Initialized
            </Typography>
          );
        }
        return null; // Default return if none of the conditions match
      },
    },
    {
      field: "complexity",
      headerName: "Complexity",
      width: 100,
      renderCell: (params) => {
        const complexity = params.value;

        if (complexity === 1) {
          return "Simple";
        } else if (complexity === 2) {
          return (
            <Typography sx={{ color: "#a88921", fontWeight: "bold" }}>
              Complex
            </Typography>
          );
        } else if (complexity === 3) {
          return (
            <Typography sx={{ color: "red", fontWeight: "bold" }}>
              Urgent
            </Typography>
          );
        }

        return null;
      },
    },
    {
      field: "signStatus",
      headerName: "Sign Status",
      width: 100,
      renderCell: (params) => {
        const { autoInitials, manualInitials, signedDateTime } = params.row;

        const parsedAutoInitials = autoInitials ? JSON.parse(autoInitials) : [];
        const parsedManualInitials = manualInitials
          ? JSON.parse(manualInitials)
          : [];

        const combinedSignatories = [
          ...parsedAutoInitials,
          ...parsedManualInitials,
        ];

        const signatoriesIds = combinedSignatories.map(
          (signatory) => signatory?.id
        );

        let isInitialized = false;

        if (SDSSecIds.includes(auth?.unitId)) {
          if (signatoriesIds.includes(1)) {
            isInitialized = true;
          }
        } else if (ASDSSecIds.includes(auth?.unitId)) {
          if (signatoriesIds.includes(2)) {
            isInitialized = true;
          }
        } else if (signatoriesIds.includes(auth?.unitId)) {
          isInitialized = true;
        }

        return signedDateTime ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "green",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                mr: 1,
              }}
            >
              Signed
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "20px",
              }}
            >
              <FaRegCircleCheck />
            </Box>
          </Box>
        ) : isInitialized ? (
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#656791",
            }}
          >
            Initialized
          </Typography>
        ) : null;
      },
    },
    { field: "title", headerName: "Doc Title", width: 200 },
    { field: "primarySource", headerName: "Primary Source", width: 200 },
    {
      field: "lastSource",
      headerName: "Last Source",
      width: 200,
      valueGetter: (params) => {
        const parsedLastSources = JSON.parse(params.value);

        let lastSource;

        if (parsedLastSources.length === 1) {
          lastSource = parsedLastSources ? parsedLastSources[0] : null;
        } else {
          lastSource = parsedLastSources ? parsedLastSources[1] : null;
        }

        return lastSource?.unit;
      },
    },
    {
      field: "destinations",
      headerName: "Destination",
      width: 200,
      valueGetter: (params) => {
        const parsedDestinations = JSON.parse(params.value);

        return parsedDestinations
          .map((destination) => destination.unit)
          .join(", ");
      },
    },
    { field: "docType", headerName: "Doc Type", width: 180 },
    {
      field: "createdAtDateTime",
      headerName: "Uploaded Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "lastUpdateDateTime",
      headerName: "Last Updated Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "firstAcceptedDateTime",
      headerName: "First Accepted Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "lastAcceptedDateTime",
      headerName: "Last Accepted Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "signedDateTime",
      headerName: "Signed Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 120,
      valueGetter: (params) => {
        const { firstAcceptedDateTime, signedDateTime } = params.row;

        if (!firstAcceptedDateTime) return null;

        // const formattedAcceptTimeStamp = firstAcceptedDateTime
        //   ? dayjs(firstAcceptedDateTime)
        //   : null;
        // const formattedSignedTimeStamp = signedDateTime
        //   ? dayjs(signedDateTime)
        //   : null;

        const firstDate = new Date(firstAcceptedDateTime);
        const endDate = signedDateTime ? new Date(signedDateTime) : new Date();

        const diffInMilliseconds = new Date(endDate) - firstDate;

        const diffInDays = Math.floor(
          diffInMilliseconds / (1000 * 60 * 60 * 24)
        );

        return diffInDays;
      },
    },

    { field: "remarks", headerName: "Remarks", width: 250 },
    { field: "annotation", headerName: "Annotation", width: 250 },
  ];

  useEffect(() => {
    setLoading(loadingState);
  }, []);

  useEffect(() => {
    setLoadingState(loading);
  }, [loading]);

  return (
    <Box>
      <ManualSignModal
        open={openSetSignPositionModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenSetSignPositionModal(false);
        }}
        pdfUrl={pdfUrl}
        loadingState={loading}
        setOpenViewModal={setOpenViewModal}
        dataFromActions={dataFromActions || null}
        setDataFromActions={setDataFromActions}
        updateTableFunction={() => updateTableFunction()}
      />
      <AutoSignModal
        open={openAutoSignModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenAutoSignModal(false);
        }}
        pdfUrl={pdfUrl}
        loadingState={loading}
        setOpenAutoSignModal={setOpenAutoSignModal}
        dataFromActions={dataFromActions || null}
        setDataFromActions={setDataFromActions}
        updateTableFunction={() => updateTableFunction()}
      />
      <ViewDocumentModal
        open={openViewModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenViewModal(false);
        }}
        pdfUrl={pdfUrl}
        loadingState={loading}
        setOpenViewModal={setOpenViewModal}
        handleAutoDetectSign={handleAutoDetectSign}
        handleManualSigning={handleManualSigning}
        dataFromActions={dataFromActions || null}
        setDataFromActions={setDataFromActions}
        updateTableFunction={() => updateTableFunction()}
      />
      <ViewDetailsModal
        open={openViewDetailsModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenViewDetailsModal(false);
        }}
        dataFromActions={dataFromActions || null}
      />
      <EditableTable
        data={data}
        columns={columns}
        checkbox={showCheckbox}
        multipleSelection={showMultipleSelection}
        // loading={loading || loadingState}
        loading={loadingState}
        // rowToDelete={setRowToDelete}
        singleSelect={singleSelect}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        height="60vh"
        showSearch
      />
    </Box>
  );
}
