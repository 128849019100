import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Typography } from "@mui/material";
import AddUserModal from "modals/users/AddUserModal";
import UpdateUserModal from "modals/users/UpdateUserModal";
import useAxiosPrivate from "contexts/interceptors/axios";
import UsersTable from "./UsersTable";

export default function Users() {
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const handleOpen = (type) => {
    if (type === "add") {
      setOpenAddModal(true);
    } else if (type === "update") {
      setOpenUpdateModal(true);
    }
  };

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/user/getAllUsers`)
      .then((e) => {
        setData(e.data);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  useEffect(() => {
    if (!selectedUser?.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedUser]);

  return (
    <Box
      sx={{
        p: "20px",
      }}
    >
      <AddUserModal
        handleClose={() => setOpenAddModal(false)}
        open={openAddModal}
        updateTableFunction={() => {
          handleGetAll();
        }}
      />
      <UpdateUserModal
        handleClose={() => setOpenUpdateModal(false)}
        open={openUpdateModal}
        data={selectedUser && selectedUser[0]}
        setSelectedUser={setSelectedUser}
        updateTableFunction={() => {
          handleGetAll();
        }}
      />

      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          Users
        </Typography>
      </Box>
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => handleOpen("add")}
            sx={{
              backgroundColor: "green",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "5px 20px",
              borderRadius: "20px",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
              mr: 2,
              "&:hover": {
                backgroundColor: "#f6e247",
                color: "#59395c",
                fontWeight: "bold",
              },
            }}
          >
            <AddIcon sx={{ mr: "10px" }} />
            Add
          </Button>
          <Button
            onClick={() => handleOpen("update")}
            disabled={disabled}
            sx={{
              backgroundColor: disabled ? "lightgray" : "#246fc9",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "5px 20px",
              borderRadius: "20px",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "#f6e247",
                color: "#59395c",
                fontWeight: "bold",
              },
            }}
          >
            <EditIcon sx={{ mr: "10px" }} />
            Update
          </Button>
        </Box>
        <Box>
          {error && (
            <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {error}
              </Typography>
            </Box>
          )}
          <UsersTable
            data={data}
            selectedData={selectedUser}
            setSelectedData={setSelectedUser}
            loadingState={loading}
            updateTableFunction={() => {
              handleGetAll();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
