/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Close";
import ShortcutIcon from "@mui/icons-material/Shortcut";

import { useEffect, useState } from "react";
import useAxiosPrivate from "contexts/interceptors/axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useStateContext } from "contexts/ContextProvider";
import { enqueueSnackbar } from "notistack";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 2,
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function AcceptDocumentsModal({
  open,
  handleClose,
  loadingState,
  selectedData,
  updateTableFunction,
}) {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [isForSignature, setIsForSignature] = useState(false);

  const manyDocuments = selectedData?.length > 1;

  const handleSubmit = () => {
    const confirmed = window.confirm(
      `Are you sure you want to accept ${manyDocuments ? "these" : "this"} ${
        manyDocuments ? "documents" : "document"
      }?`
    );

    if (confirmed) {
      setLoading(true);
      setError("");

      axiosPrivate
        .patch(`/documents/acceptDocs`, {
          documents: selectedData,
          updateFields: {
            currentOwner: auth.unitId,
            status:
              (auth?.role === "unit head" || auth?.role === "chief") &&
              isForSignature
                ? 7
                : (auth?.role === "unit head" || auth?.role === "chief") &&
                  !isForSignature
                ? 3
                : isForSignature
                ? 7
                : !isForSignature
                ? 8
                : 1,
            acceptStatus: 1,
            remarks: `Accepted by ${auth?.firstName} ${auth?.lastName} from ${auth?.unitName}`,
          },
        })
        .then(() => {
          enqueueSnackbar(
            `Document${selectedData.length > 1 ? "s" : ""} Accepted`,
            {
              variant: "success",
            }
          );
          updateTableFunction();
          handleClose();
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {loading && (
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box sx={style}>
          <Box
            sx={{
              position: "absolute",
              right: 20,
              cursor: "pointer",
              zIndex: 100,
            }}
          >
            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: "#59395c",
                borderRadius: "10px",
                width: "70%",
                p: 2,
                mb: 2,
              }}
            >
              <Typography
                sx={{ ml: 4, fontSize: 25, fontWeight: "bold", color: "#fff" }}
              >
                ACCEPT DOCUMENTS
              </Typography>
            </Box>
            {error && (
              <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
                p: 4,
                mx: 4,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  background: "#ebebeb",
                  border: "solid 1px #b6b6b6",
                  p: 2,
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "gray",
                    mb: 2,
                  }}
                >
                  {`What ${manyDocuments ? "are" : "is"} ${
                    manyDocuments ? "these" : "this"
                  } ${manyDocuments ? "documents" : "document"} for?`}
                </Typography>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={!isForSignature}
                      onChange={() => setIsForSignature((prev) => !prev)}
                      color="success"
                    />
                    <Typography
                      sx={{
                        fontWeight: isForSignature ? "normal" : "bold",
                        color: isForSignature ? "gray" : "green",
                      }}
                    >
                      Accept
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={isForSignature}
                      onChange={() => setIsForSignature((prev) => !prev)}
                      color="success"
                    />
                    <Typography
                      sx={{
                        fontWeight: isForSignature ? "bold" : "normal",
                        color: isForSignature ? "green" : "gray",
                      }}
                    >
                      Signature
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 2,
              zIndex: 10,
              mt: 2,
            }}
          >
            <Button
              onClick={() => handleSubmit()}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#59395c",
                color: "#fff",
                py: 1,
                px: 2,
                // width: "10vw",
                // minWidth: "100px",
                "&:hover": {
                  backgroundColor: "#f6e247",
                  color: "#59395c",
                  fontWeight: "bold",
                },
              }}
            >
              <ShortcutIcon
                sx={{
                  mr: 1,
                }}
              />
              Accept
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
