/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";

import { AiFillSignature } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";

import { useStateContext } from "contexts/ContextProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "98vh",
  width: "98vw",
  bgcolor: "background.paper",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  overflowY: "auto",
  p: 2,
};

export default function ViewDocumentModal({
  open,
  handleClose,
  pdfUrl,
  loadingState,
  setOpenViewModal,
  handleAutoDetectSign,
  handleManualSigning,
  dataFromActions,
}) {
  const { auth } = useStateContext();

  const [rowData, setRowData] = useState();
  const [isSelecting, setIsSelecting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [destinations, setDestinations] = useState([]);

  const userRolesWithSignature = ["sds", "asds", "chief", "unit head"];

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  useEffect(() => {
    if (dataFromActions) {
      if (Array.isArray(dataFromActions) && dataFromActions.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        setRowData(dataFromActions[0]);
      } else {
        setRowData(dataFromActions);
      }
    }
  }, [dataFromActions]);

  useEffect(() => {
    if (rowData) {
      const parsedAutoInitials = rowData.autoInitials
        ? JSON.parse(rowData.autoInitials)
        : [];
      const parsedManualInitials = rowData.manualInitials
        ? JSON.parse(rowData.manualInitials)
        : [];

      const combinedSignatories = [
        ...parsedAutoInitials,
        ...parsedManualInitials,
      ];

      const signatoriesIds = combinedSignatories.map(
        (signatory) => signatory?.id
      );

      setDestinations(JSON.parse(rowData.destinations));

      setDisabled(
        auth.unitId !== 1 &&
          (signatoriesIds.includes(auth.unitId) ||
            combinedSignatories.length >= 4 ||
            loadingState)
      );

      // setDisabled(
      //   auth.unitId === 1
      //     ? false
      //     : signatoriesIds.includes(auth.unitId) ||
      //         combinedSignatories.length >= 4 ||
      //         loadingState
      // );
    }
  }, [rowData]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setIsSelecting(true);
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {isSelecting &&
          (auth.role === "sds" ||
            auth.role === "asds" ||
            auth.role === "chief" ||
            auth.role === "unit head") &&
          dataFromActions?.status === 7 && (
            <Box
              sx={{
                ...style,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                height: "100%",
                width: "100%",
                zIndex: 100,
              }}
            >
              <Box
                onClick={() => {
                  setOpenViewModal(false);
                  setIsSelecting(false);
                  handleAutoDetectSign(); // Open auto detect signing modal
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  transition: "ease-in-out 0.3s",
                  cursor: "pointer",
                  height: "20vh",
                  width: "25vh",
                  minHeight: "150px",
                  minWidth: "150px",
                  p: 2,
                  mr: 6,
                  "&:hover": {
                    mt: -2,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "solid 1px gray",
                      borderRadius: "50%",
                      height: "50px",
                      width: "50px",
                      mb: 2,
                    }}
                  >
                    <AiFillSignature style={{ fontSize: "20px" }} />
                  </Box>
                  Auto-Detect Signature Position
                </Box>
              </Box>
              <Box
                onClick={() => {
                  setOpenViewModal(false);
                  setIsSelecting(false);
                  handleManualSigning(); // Open manual signing modal
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  transition: "ease-in-out 0.3s",
                  cursor: "pointer",
                  height: "20vh",
                  width: "25vh",
                  minHeight: "150px",
                  minWidth: "150px",
                  p: 2,
                  mr: 6,
                  "&:hover": {
                    mt: -2,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "solid 1px gray",
                      borderRadius: "50%",
                      height: "50px",
                      width: "50px",
                      mb: 2,
                    }}
                  >
                    <PictureInPictureAltIcon />
                  </Box>
                  Select Signature Position
                </Box>
              </Box>
              <Box
                onClick={() => {
                  setIsSelecting(false);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  transition: "ease-in-out 0.3s",
                  cursor: "pointer",
                  height: "20vh",
                  width: "25vh",
                  minHeight: "150px",
                  minWidth: "150px",
                  p: 2,
                  mr: 6,
                  "&:hover": {
                    mt: -2,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "solid 1px gray",
                      borderRadius: "50%",
                      height: "50px",
                      width: "50px",
                      mb: 2,
                    }}
                  >
                    <CloseIcon />
                  </Box>
                  Cancel
                </Box>
              </Box>
            </Box>
          )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {`Title: ${dataFromActions?.title}`}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {userRolesWithSignature.includes(auth?.role) &&
            destinations?.some((dest) => dest?.id === auth?.unitId) &&
            dataFromActions?.status === 7 &&
            dataFromActions?.acceptStatus === 1 ? (
              <Box
                sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
              >
                {disabled && (
                  <Typography sx={{ color: "red", fontSize: "12px", mr: 2 }}>
                    Either you already signed this document or the total number
                    of signatories is reached
                  </Typography>
                )}
                <Button
                  disabled={disabled}
                  onClick={() => setIsSelecting(true)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: disabled ? "lightgray" : "#59395c",
                    color: disabled ? "black" : "#fff",
                    py: 1,
                    width: "10vw",
                    minWidth: "200px",
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "#f6e247",
                      color: "#59395c",
                      fontWeight: "bold",
                    },
                  }}
                >
                  {auth.unitId === 1 ? "Sign Document" : "Initialize Document"}
                </Button>
              </Box>
            ) : dataFromActions?.status === 6 ? (
              <Button
                disabled={loadingState}
                // onClick={() => handleSign()}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#59395c",
                  color: "#fff",
                  py: 1,
                  width: "10vw",
                  minWidth: "200px",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "#f6e247",
                    color: "#59395c",
                    fontWeight: "bold",
                  },
                }}
              >
                Unsign Document
              </Button>
            ) : (
              <Box />
            )}

            <IconButton disabled={loadingState} onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100vh",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
            width: "100%",
            mt: 2,
          }}
        >
          {pdfUrl ? (
            <iframe
              src={pdfUrl}
              title="PDF Viewer"
              height="100%"
              width="100%"
            />
          ) : (
            <Typography>No picture</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
