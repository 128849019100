import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import EditableTable from "components/Table/EditableTable";
// import useAxiosPrivate from "contexts/interceptors/axios";
// import ConfirmationModal from "modals/miscellaneous/ConfirmationModal";
import ViewSignatureModal from "modals/users/ViewSignatureModal";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import UploadSignatureModal from "modals/users/UploadSignatureModal";
// import { enqueueSnackbar } from "notistack";

export default function UsersTable({
  data,
  selectedData,
  setSelectedData,
  loadingState,
  updateTableFunction,
}) {
  // const axiosPrivate = useAxiosPrivate();
  const [selectedUser, setSelectedUser] = useState();
  // const [rowToDelete, setRowToDelete] = useState();

  const [openViewSignatureModal, setOpenViewSignatureModal] = useState(false);
  const [openUploadSignatureModal, setOpenUploadSignatureModal] =
    useState(false);
  const [dataFromActions, setDataFromActions] = useState();

  const [hoveredRowId, setHoveredRowId] = useState(null);

  // const [open, setOpen] = useState(false);
  // const [promptResponse, setPromptResponse] = useState(null);
  // const [submit, setSubmit] = useState(false);
  // const [submitKind, setSubmitKind] = useState("");
  // const [promptDesc, setPromptDesc] = useState("");

  // const [loading, setLoading] = useState(false);

  setSelectedData(selectedUser);

  // useEffect(() => {
  //   if (rowToDelete) {
  //     setPromptDesc("Are you sure you want to delete this data?");
  //     setSubmitKind("delete");
  //     setOpen(true);
  //   }
  // }, [rowToDelete]);

  // useEffect(() => {
  //   if (!open && submit && promptResponse) {
  //     setLoading(true);

  //     axiosPrivate
  //       .delete(`/user/delete/${rowToDelete ? rowToDelete[0]?.uid : ""}`)
  //       .then(() => {
  //         enqueueSnackbar("User deleted", {
  //           variant: "success",
  //         });
  //         setRowToDelete(null);
  //         setSubmit(false);
  //         updateTableFunction();
  //       })
  //       .catch((err) => {
  //         enqueueSnackbar(err?.message, {
  //           variant: "error",
  //         });
  //         setRowToDelete(null);
  //         setSubmit(false);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [submit, promptResponse]);

  const handleViewDetails = async (rowData) => {
    if (rowData) {
      setDataFromActions(rowData);
      setOpenViewSignatureModal(true);
    }
  };

  const handleUploadSignature = async (rowData) => {
    if (rowData) {
      setDataFromActions(rowData);
      setOpenUploadSignatureModal(true);
    }
  };

  const columns = [
    // { field: "uid", headerName: "ID", width: 70 },
    {
      headerName: "View",
      width: 100,
      align: "center",
      renderCell: (params) => {
        const { signPath, role } = params.row;

        const isHovered = hoveredRowId === params.id;

        return signPath ? (
          <Button
            onClick={() => handleViewDetails(params.row)}
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#4b51a8",
              color: "#fff",
              borderRadius: "15px",
              "&:hover": {
                backgroundColor: "#5b63da",
                fontWeight: "bold",
              },
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "10px" }}>View</Typography>
              <Typography sx={{ fontSize: "10px" }}>Signature</Typography>
            </Box>
          </Button>
        ) : (
          <Box
            onMouseEnter={() => setHoveredRowId(params.id)}
            onMouseLeave={() => setHoveredRowId(null)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            {isHovered &&
            ["sds", "asds", "chief", "unit head"].includes(role) ? (
              <Button onClick={() => handleUploadSignature(params.row)}>
                Upload
              </Button>
            ) : (
              <Typography sx={{ fontSize: "10px" }}>No Signature</Typography>
            )}
          </Box>
        );
      },
    },
    { field: "username", headerName: "Username", width: 150 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 150 },
    {
      field: "role",
      headerName: "Role",
      width: 150,
    },
    {
      field: "officeName",
      headerName: "Office",
      width: 300,
    },
    {
      field: "unitName",
      headerName: "Unit",
      width: 300,
    },
    { field: "password", headerName: "Password", width: 150 },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      {/* <ConfirmationModal
        open={open}
        handleClose={() => setOpen(false)}
        setPromptResponse={setPromptResponse}
        setSubmit={setSubmit}
        submitKind={submitKind}
        promptDesc={promptDesc}
      /> */}
      <ViewSignatureModal
        open={openViewSignatureModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenViewSignatureModal(false);
        }}
        dataFromActions={dataFromActions || null}
      />
      <UploadSignatureModal
        open={openUploadSignatureModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenUploadSignatureModal(false);
        }}
        dataFromActions={dataFromActions || null}
        updateTableFunction={() => updateTableFunction()}
      />
      <EditableTable
        data={data}
        columns={columns}
        checkbox
        loading={loadingState}
        singleSelect
        selectedData={selectedData}
        setSelectedData={setSelectedUser}
        // rowToDelete={setRowToDelete}
        height="60vh"
        showSearch
      />
    </Box>
  );
}
