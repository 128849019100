/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { useStateContext } from "contexts/ContextProvider";
import { Box, Button, Typography } from "@mui/material";

import SummarizeIcon from "@mui/icons-material/Summarize";

import AddIcon from "@mui/icons-material/Add";
import DocuTypeIcon from "@mui/icons-material/InsertDriveFile";
// import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import { AiFillSignature } from "react-icons/ai";
import { TbPencilCheck, TbSignatureOff } from "react-icons/tb";
import DownloadIcon from "@mui/icons-material/FileDownload";
import OfficeIcon from "@mui/icons-material/HomeWork";
import PanToolIcon from "@mui/icons-material/PanTool";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { PiOfficeChairFill } from "react-icons/pi";
import SaveIcon from "@mui/icons-material/Save";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import UploadIcon from "@mui/icons-material/Upload";
import UsersIcon from "@mui/icons-material/PeopleAlt";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import useAxiosPrivate from "contexts/interceptors/axios";
import AddDocumentModal from "modals/documents/AddDocumentModal";
import ResponsiveCards from "components/ResponsiveCards";
// import DashboardTable from "./DashboardTable";

export default function Dashboard() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [approval, setApproval] = useState([]);

  const [routed, setRouted] = useState([]);
  const [incoming, setIncoming] = useState([]);
  const [outgoing, setOutgoing] = useState([]);
  const [pending, setPending] = useState([]);
  const [saved, setSaved] = useState([]);
  const [lapsed, setLapsed] = useState([]);
  const [onhold, setOnhold] = useState([]);
  const [signed, setSigned] = useState([]);
  const [unsigned, setUnsigned] = useState([]);

  const [forSigningDocs, setForSigningDocs] = useState([]);
  const [forRoutingDocs, setForRoutingDocs] = useState([]);
  const [signedDocs, setSignedDocs] = useState([]);
  const [routedDocs, setRoutedDocs] = useState([]);
  const [incomingDocs, setIncomingDocs] = useState([]);
  const [outgoingDocs, setOutgoingDocs] = useState([]);

  const [docTypes, setDocTypes] = useState([]);
  const [offices, setOffices] = useState([]);
  const [units, setUnits] = useState([]);
  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openAddModal, setOpenAddModal] = useState(false);

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        // const filteredRouted = res?.data?.filter((doc) => {
        //   const lastSources = JSON.parse(doc?.lastSource);

        //   let lastSource;

        //   if (lastSources.length === 1) {
        //     lastSource = lastSources ? lastSources[0].id : null;
        //   } else {
        //     lastSource = lastSources ? lastSources[1].id : null;
        //   }

        //   if (lastSource === 1) {
        //     if (auth.unitId === 3 || auth.unitId === 4 || auth.unitId === 5) {
        //       if (doc.currentOwner !== auth.unitId && doc.status === 9) {
        //         return true;
        //       }
        //     }
        //   } else if (doc.currentOwner !== auth.unitId && doc.status === 9) {
        //     return true;
        //   }
        //   return false;
        // });

        const filteredApproval = res?.data?.filter((doc) => {
          if (
            doc.currentOwner !== auth.unitId &&
            doc.status === 8 &&
            doc.acceptStatus === 0
          ) {
            return true;
          }
          return false;
        });

        setApproval(filteredApproval);

        const filteredRouted = res?.data?.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          if (
            doc.status === 9 &&
            destinations.some((dest) => dest.id === auth.unitId)
          ) {
            return true;
          }
          return false;
        });

        setRouted(filteredRouted);

        const filteredIncoming = res?.data?.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          // const lastSources = JSON.parse(doc?.lastSource);

          // let lastSource;

          // if (lastSources.length === 1) {
          //   lastSource = lastSources ? lastSources[0].id : null;
          // } else {
          //   lastSource = lastSources ? lastSources[1].id : null;
          // }

          // if (lastSource === 1) {
          //   if (auth.role === "secretary") {
          //     if (doc.currentOwner !== 4 && doc.status === 1) {
          //       return true;
          //     }
          //   }
          // } else

          if (SDSSecIds.includes(auth.unitId)) {
            if (
              destinations.some((dest) => SDSSecIds.includes(dest.id)) &&
              !SDSSecIds.includes(doc.currentOwner) &&
              doc.status === 1
            ) {
              return true;
            }
          } else if (ASDSSecIds.includes(auth.unitId)) {
            if (
              destinations.some((dest) => ASDSSecIds.includes(dest.id)) &&
              !ASDSSecIds.includes(doc.currentOwner) &&
              doc.status === 1
            ) {
              return true;
            }
          } else if (
            destinations.some((dest) => dest.id === auth.unitId) &&
            doc.currentOwner !== auth.unitId &&
            doc.status === 1
          ) {
            return true;
          }
          return false;
        });

        setIncoming(filteredIncoming);

        const ownedDocs = res.data.filter(
          (doc) => auth.unitId === doc.currentOwner
        );

        const filteredOutgoing = ownedDocs.filter((doc) => {
          const lastSources = JSON.parse(doc?.lastSource);
          const destinations = JSON.parse(doc?.destinations);
          const compiledDestinations = destinations.map((dest) => dest.id);

          let lastSource;

          if (lastSources.length === 1) {
            lastSource = lastSources ? lastSources[0].id : null;
          } else {
            lastSource = lastSources ? lastSources[1].id : null;
          }

          if (SDSSecIds.includes(auth.unitId)) {
            if (
              destinations.some((dest) => dest.id === 1) &&
              SDSSecIds.includes(doc.currentOwner) &&
              (doc.status === 7 || doc.status === 8)
            ) {
              return true;
            }
          } else if (ASDSSecIds.includes(auth.unitId)) {
            if (
              destinations.some((dest) => dest.id === 2) &&
              ASDSSecIds.includes(doc.currentOwner) &&
              (doc.status === 7 || doc.status === 8)
            ) {
              return true;
            }
          }

          if (doc.status === 2) {
            return (
              compiledDestinations.includes(auth.unitId) && doc.status === 2
            );
          }
          return (
            (doc.primSourceId === auth.unitId || lastSource === auth.unitId) &&
            (doc.status === 1 || doc.status === 8)
          );
        });

        setOutgoing(filteredOutgoing);

        let filteredPending;

        if (SDSSecIds.includes(auth.unitId)) {
          filteredPending = res.data.filter(
            (doc) => doc.status === 3 && doc.currentOwner === 4
          );
        } else {
          filteredPending = res.data.filter(
            (doc) => doc.status === 3 && doc.currentOwner === auth?.unitId
          );
        }

        setPending(filteredPending);

        const filteredSaved = res.data.filter(
          (doc) => doc.status === 4 && doc.currentOwner === auth.unitId
        );

        setSaved(filteredSaved);

        const fifteenDaysInMilliseconds = 15 * 24 * 60 * 60 * 1000;

        const filteredLapsed = res.data.filter((doc) => {
          const { createdAtDateTime, status } = doc;

          const currentDateTime = new Date();
          const createdAtDateTimeObj = new Date(createdAtDateTime);
          // const lastUpdateDateTimeObj = new Date(lastUpdateDateTime);

          if (status === 3) {
            // if (lastUpdateDateTime) {
            //   if (
            //     currentDateTime.getTime() >=
            //     lastUpdateDateTimeObj.getTime() + fifteenDaysInMilliseconds
            //   )
            //     return doc;
            // } else
            if (createdAtDateTime) {
              if (
                currentDateTime.getTime() >=
                createdAtDateTimeObj.getTime() + fifteenDaysInMilliseconds
              ) {
                return doc;
              }
            }
          }
          return null;
        });

        setLapsed(filteredLapsed);

        const filteredOnhold = res.data.filter(
          (doc) => doc.status === 5 && doc.currentOwner === auth.unitId
        );

        setOnhold(filteredOnhold);

        const filteredSigned = res.data.filter((doc) => doc?.status === 6);

        setSigned(filteredSigned);

        const filteredUnsigned = res.data.filter(
          (doc) => doc.primSourceId === auth?.unitId && doc.status === 7
        );

        setUnsigned(filteredUnsigned);

        // SDS, ASDS, Chief

        const filteredForRoutingDocs = res.data.filter((doc) => {
          const destinations = JSON.parse(doc.destinations);

          if (
            destinations.some((dest) => dest.id === auth.unitId) &&
            // doc.currentOwner !== auth.unitId &&
            doc.status === 8 &&
            doc.acceptStatus === 1
          ) {
            return true;
          }
          return false;
        });

        setForRoutingDocs(filteredForRoutingDocs);

        const filteredForSigningDocs = res.data.filter((doc) => {
          const destinations = JSON.parse(doc.destinations);

          if (
            destinations.some((dest) => dest.id === auth.unitId) &&
            doc.status === 7 &&
            doc.acceptStatus === 1
          ) {
            return true;
          }
          return false;
        });

        setForSigningDocs(filteredForSigningDocs);

        const filteredSignedDocs = res.data.filter((doc) => {
          const parsedAutoInitials = doc.autoInitials
            ? JSON.parse(doc.autoInitials)
            : [];
          const parsedManualInitials = doc.manualInitials
            ? JSON.parse(doc.manualInitials)
            : [];

          const combinedSignatories = [
            ...parsedAutoInitials,
            ...parsedManualInitials,
          ];

          if (combinedSignatories.some((sign) => sign?.id === auth?.unitId)) {
            return true;
          }

          return false;
        });

        setSignedDocs(filteredSignedDocs);

        const filteredRoutedDocs = res?.data?.filter((doc) => {
          const lastSources = JSON.parse(doc?.lastSource);

          if (
            doc.status === 9 &&
            lastSources.some((last) => last.id === auth.unitId)
          ) {
            return true;
          }
          return false;
        });

        setRoutedDocs(filteredRoutedDocs);

        const filteredIncomingDocs = res?.data?.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          if (
            destinations.some((dest) => dest.id === auth.unitId) &&
            doc.currentOwner !== auth.unitId &&
            (doc.status === 7 || doc.status === 8)
          ) {
            return true;
          }
          return false;
        });

        setIncomingDocs(filteredIncomingDocs);

        // Code for outgoing documents for SDS and ASDS are copied from the way it is filtered for others
        setOutgoingDocs(filteredOutgoing);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetAllDocTypes = () => {
    axiosPrivate
      .get(`/libraries/getAllDocTypes`)
      .then((res) => {
        setDocTypes(res.data);
      })
      .catch((err) => setError(err.message));
  };

  const handleGetAllOffices = () => {
    axiosPrivate
      .get(`/libraries/getAllOffices`)
      .then((res) => {
        setOffices(res.data);
      })
      .catch((err) => setError(err.message));
  };

  const handleGetAllUnits = () => {
    axiosPrivate
      .get(`/libraries/getAllUnits`)
      .then((res) => {
        setUnits(res.data);
      })
      .catch((err) => setError(err.message));
  };

  const handleGetAllUsers = () => {
    axiosPrivate
      .get(`/user/getAllUsers`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => setError(err.message));
  };

  useEffect(() => {
    if (auth?.role === "admin") {
      setLoading(true);
      setError("");

      Promise.all([
        handleGetAllDocTypes(),
        handleGetAllOffices(),
        handleGetAllUnits(),
        handleGetAllUsers(),
      ])
        .then(() => setLoading(false))
        .catch((err) => setError(err.message))
        .finally(() => setLoading(false));
    } else {
      handleGetAll();
    }
  }, [auth]);

  let contents = [];

  if (
    auth.role === "unit employee" ||
    auth.role === "unit head" ||
    auth.role === "secretary" ||
    auth.role === "chief"
  ) {
    contents = [
      {
        path: "/incoming",
        value: incoming.length || 0,
        title: "Incoming Documents",
        iconColor: "linear-gradient(120deg, #27bf13, #207a14)",
        icon: (
          <DownloadIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/outgoing",
        value: outgoing.length || 0,
        title: "Outgoing Documents",
        iconColor: "linear-gradient(120deg, #246fc9, #184c8c)",
        icon: (
          <UploadIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/pending",
        value: pending.length || 0,
        title: "Pending Documents",
        iconColor: "linear-gradient(120deg, #f09116, #a8650d)",
        icon: (
          <PendingActionsIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/saved",
        value: saved.length || 0,
        title: "Saved Documents",
        iconColor: "linear-gradient(120deg, #00a8f3, #095b80)",
        icon: (
          <SaveIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/lapsed",
        value: lapsed.length || 0,
        title: "Lapsed Documents",
        iconColor: "linear-gradient(120deg, #cf1515, #9319a6)",
        icon: (
          <WarningAmberIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/onhold",
        value: onhold.length || 0,
        title: "On-Hold Documents",
        iconColor: "linear-gradient(120deg, #edce52, #cda814)",
        icon: (
          <PanToolIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/signed",
        value: signed.length || 0,
        title: "Signed Documents",
        iconColor: "linear-gradient(120deg, #3fde14, #2e8516)",
        icon: (
          <TbPencilCheck
            style={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/unsigned",
        value: unsigned.length || 0,
        title: "Unsigned Documents",
        iconColor: "linear-gradient(120deg, #ff7979, #936b4d)",
        icon: (
          <TbSignatureOff
            style={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
    ];
  } else if (auth.role === "sds" || auth.role === "asds") {
    contents = [
      {
        path: "/routing",
        value: forRoutingDocs.length || 0,
        title: "Documents for Routing",
        iconColor: "linear-gradient(120deg, #f09116, #a8650d)",
        icon: (
          <ShortcutIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/signature",
        value: forSigningDocs.length || 0,
        title: "Documents for Signature",
        iconColor: "linear-gradient(120deg, #246fc9, #184c8c)",
        icon: (
          <AiFillSignature
            style={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/signedDocs",
        value: signedDocs.length || 0,
        title: "Signed Documents",
        iconColor: "linear-gradient(120deg, #27bf13, #207a14)",
        icon: (
          <TbPencilCheck
            style={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/routedDocs",
        value: routedDocs.length || 0,
        title: "Routed Documents",
        iconColor: "linear-gradient(120deg, #d75c94, #e79a2b)",
        icon: (
          <SummarizeIcon
            style={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
    ];
  } else if (auth.role === "admin") {
    contents = [
      {
        path: "/doc-types",
        value: docTypes.length || 0,
        title: "Document Types",
        iconColor: "linear-gradient(120deg, #f54848, #621f1f)",
        icon: (
          <DocuTypeIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/offices",
        value: offices.length || 0,
        title: "Offices",
        iconColor: "linear-gradient(120deg, #27bf13, #207a14)",
        icon: (
          <OfficeIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/units",
        value: units.length || 0,
        title: "Units",
        iconColor: "linear-gradient(120deg, #f09116, #a8650d)",
        icon: (
          <PiOfficeChairFill
            style={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
      {
        path: "/users",
        value: users.length || 0,
        title: "Users",
        iconColor: "linear-gradient(120deg, #00a8f3, #095b80)",
        icon: (
          <UsersIcon
            sx={{
              color: "lightgray",
              fontSize: "30px",
            }}
          />
        ),
      },
    ];
  }

  if (auth.role === "secretary") {
    contents.push({
      path: "/approval",
      value: approval.length || 0,
      title: "Documents for Approval",
      iconColor: "linear-gradient(120deg, #2959ba, #1ea2df, #171778)",
      icon: (
        <ThumbUpIcon
          style={{
            color: "lightgray",
            fontSize: "30px",
          }}
        />
      ),
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f0f0f0",
        px: 7,
        py: 2,
      }}
    >
      <AddDocumentModal
        open={openAddModal}
        handleClose={() => setOpenAddModal(false)}
        loadingState={loading}
        updateTableFunction={() => handleGetAll()}
      />
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
          mb: 2,
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ textAlign: "left", fontSize: "30px", fontWeight: "bold" }}
          >
            Welcome to the Dashboard!
          </Typography>
          <Typography
            sx={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}
          >
            {`${auth?.firstName} ${auth?.lastName}`}
          </Typography>
        </Box>

        {auth.role !== "admin" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={loading}
              onClick={() => setOpenAddModal(true)}
              sx={{
                backgroundColor: loading ? "lightgray" : "#534f7c",
                color: "#fff",
                fontSize: "15px",
                fontWeight: "bold",
                padding: "8px 20px",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  fontWeight: "bold",
                },
              }}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Document
            </Button>
          </Box>
        ) : (
          <Box />
        )}
      </Box>
      <Box sx={{ width: "100%", my: "2vw" }}>
        <ResponsiveCards contents={contents} loadingState={loading} />
      </Box>
      {(auth.role === "sds" || auth.role === "asds") && (
        <ResponsiveCards
          contents={[
            {
              path: "/incomingDocs",
              value: incomingDocs.length || 0,
              title: "Incoming Documents",
              iconColor: "linear-gradient(120deg, #e1d613, #958f21)",
              icon: (
                <DownloadIcon
                  sx={{
                    color: "lightgray",
                    fontSize: "30px",
                  }}
                />
              ),
            },
            {
              path: "/outgoingDocs",
              value: outgoingDocs.length || 0,
              title: "Outgoing Documents",
              iconColor: "linear-gradient(120deg, #df3e3e, #8a2525)",
              icon: (
                <UploadIcon
                  sx={{
                    color: "lightgray",
                    fontSize: "30px",
                  }}
                />
              ),
            },
          ]}
          loadingState={loading}
        />
      )}

      {(auth.role === "unit head" || auth.role === "chief") && (
        <Box sx={{ width: "100%" }}>
          <ResponsiveCards
            contents={[
              ...((auth.role === "unit head" || auth.role === "chief") && [
                {
                  path: "/signature",
                  value: forSigningDocs.length || 0,
                  title: "Documents for Signature",
                  // iconColor:
                  //   "linear-gradient(120deg, #b98b21, #f4a238, #ffae00, #a17c32)",
                  iconColor:
                    "linear-gradient(120deg, #f9f295, #e95c8a, #835ec1, #23a7e7)",

                  icon: (
                    <AiFillSignature
                      style={{
                        color: "lightgray",
                        fontSize: "30px",
                      }}
                    />
                  ),
                },
              ]),
              ...((auth.role === "unit employee" ||
                auth.role === "unit head" ||
                auth.role === "secretary" ||
                auth.role === "chief") && [
                {
                  path: "/routed",
                  value: routed.length || 0,
                  title: "Routed Documents",
                  // iconColor:
                  //   "linear-gradient(120deg, #b98b21, #f4a238, #ffae00, #a17c32)",
                  iconColor:
                    "linear-gradient(120deg, #78e8ce, #ff65ba, #ffb359)",

                  icon: (
                    <SummarizeIcon
                      style={{
                        color: "lightgray",
                        fontSize: "30px",
                      }}
                    />
                  ),
                },
              ]),
            ]}
            loadingState={loading}
          />
        </Box>
      )}
    </Box>
  );
}
