import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import useAxiosPrivate from "contexts/interceptors/axios";
import { enqueueSnackbar } from "notistack";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#f0f0f0",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function UpdateOfficeModal({
  open,
  handleClose,
  data,
  updateTableFunction,
}) {
  const axiosPrivate = useAxiosPrivate();
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      office: data?.office || "",
    },

    validationSchema: object().shape({
      office: string().required("Required"),
    }),
    onSubmit: () => {
      setLoading(true);
      setError("");

      axiosPrivate
        .put(`/libraries/updateOffice/${data?.id}`, formik.values)
        .then(() => {
          enqueueSnackbar("Office Updated", {
            variant: "success",
          });
          updateTableFunction();
          handleClose();
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data) {
      const initialValues = {
        office: data?.office || "",
      };
      formik.setValues(initialValues);
    }
  }, [data]);

  useEffect(() => {
    setDisabled(!formik.values.office);
  }, [formik.values]);

  const components = [
    <TextField
      name="office"
      label="Office Name"
      size="small"
      disabled={loading}
      value={formik?.values?.office}
      onChange={formik?.handleChange}
      onBlur={formik?.handleBLur}
      error={formik?.touched?.office && Boolean(formik?.errors?.office)}
      helperText={formik?.touched?.office && formik?.errors?.office}
      variant="outlined"
      fullWidth
    />,
  ];

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {loading && (
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          variant="form"
          component="form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          sx={style}
        >
          <Box>
            <Box // Fixed Header
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#59395c",
                // boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderBottomLeftRadius: "30%",
                borderBottomRightRadius: "200px",
                zIndex: 2,
                px: 4,
                mr: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    color: "#fff",
                    py: 1,
                  }}
                >
                  Update Office
                </Typography>
                <IconButton
                  onClick={handleClose}
                  sx={{ color: "#fff", p: 0, m: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            </Box>
            {error && (
              <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                m: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  backgroundColor: "#fff",
                  border: "solid 1px #b6b6b6",
                  p: 2,
                }}
              >
                {components.map((component, index) => (
                  <Box
                    sx={{
                      width: index % 2 === 0 ? "47.5%" : "50%",
                      minWidth: "200px",
                      mr: index % 2 === 0 ? "2.5%" : 0,
                      mb: "2.5%",
                    }}
                  >
                    {component}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 2,
              zIndex: 10,
            }}
          >
            <Button
              disabled={disabled}
              type="submit"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: disabled ? "lightgray" : "#59395c",
                color: "#fff",
                py: 1,
                width: "10vw",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor: "#f6e247",
                  color: "#59395c",
                  fontWeight: "bold",
                },
              }}
            >
              <SaveIcon
                sx={{
                  mr: 1,
                }}
              />
              Update
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
