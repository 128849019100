/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import EditDocumentModal from "modals/documents/EditDocumentModal";
import UpdateFileModal from "modals/documents/UpdateFileModal";

import EditIcon from "@mui/icons-material/Edit";

import DocumentsTable from "../../DocumentsTable";

export default function OutgoingDocuments() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openUpdateFileModal, setOpenUpdateFileModal] = useState(false);

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        const ownedDocs = res.data.filter(
          (doc) => auth.unitId === doc.currentOwner
        );

        const filteredOutgoingDocs = ownedDocs.filter((doc) => {
          const lastSources = JSON.parse(doc?.lastSource);
          const destinations = JSON.parse(doc?.destinations);
          const compiledDestinations = destinations.map((dest) => dest.id);

          let lastSource;

          if (lastSources.length === 1) {
            lastSource = lastSources ? lastSources[0].id : null;
          } else {
            lastSource = lastSources ? lastSources[1].id : null;
          }

          // if (doc.primSourceId === auth.unitId || lastSource === auth.unitId) {
          //   if (doc.status === 1 || doc.status === 8) {
          //     console.log("1 8: ", doc.id, lastSource);
          //   }
          //   console.log("lastSource: ", doc.id, lastSource);
          // }

          if (doc.status === 2) {
            return (
              compiledDestinations.includes(auth.unitId) && doc.status === 2
            );
          }
          return (
            (doc.primSourceId === auth.unitId || lastSource === auth.unitId) &&
            (doc.status === 1 || doc.status === 9)
          );
        });

        setDocuments(filteredOutgoingDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  // useEffect(() => {
  //   if (selectedData) {
  //     if (selectedData?.function === "edit") {
  //       setOpenEditModal(selectedData);
  //     }
  //     // if (selectedData?.function === "viewActionsReqs") {
  //     //   setOpenViewActionsModal(true);
  //     // }
  //     // if (selectedData?.function === "view") {
  //     //   handleViewFile(selectedData);
  //     // }
  //   }
  // }, [selectedData]);

  return (
    <Box
      sx={{
        overflowX: "auto",
        p: "20px",
        width: "100%",
      }}
    >
      <UpdateFileModal
        open={openUpdateFileModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenUpdateFileModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <EditDocumentModal
        open={openEditModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenEditModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        setOpenUpdateFileModal={setOpenUpdateFileModal}
        setOpenEditModal={setOpenEditModal}
        updateTableFunction={() => handleGetAll()}
      />
      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          Outgoing Documents
        </Typography>
      </Box>
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            mt: -0.5,
            minWidth: "30%",
            right: 280,
            zIndex: 1000,
          }}
        >
          <Tooltip
            title={
              selectedData &&
              selectedData[0]?.primSourceId !== auth?.unitId &&
              "Only the creator can edit documents"
            }
            placement="top"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                disabled={
                  loading ||
                  selectedData?.length === 0 ||
                  selectedData?.length > 1 ||
                  !selectedData ||
                  (selectedData &&
                    selectedData[0]?.primSourceId !== auth?.unitId)
                }
                onClick={() => setOpenEditModal(true)}
                sx={{
                  backgroundColor:
                    loading ||
                    selectedData?.length === 0 ||
                    selectedData?.length > 1 ||
                    !selectedData ||
                    (selectedData &&
                      selectedData[0]?.primSourceId !== auth?.unitId)
                      ? "lightgray"
                      : "#184c8c",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "5px 20px",
                  borderRadius: "15px",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "lightgray",
                    color: "#2f2f2f",
                    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <EditIcon sx={{ mr: 1 }} />
                Revise
              </Button>
            </Box>
          </Tooltip>
        </Box>

        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          <DocumentsTable
            data={documents}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            showCheckbox
            singleSelect
            loadingState={loading}
            setLoadingState={setLoading}
            updateTableFunction={handleGetAll}
          />
        </Box>
      </Box>
    </Box>
  );
}
