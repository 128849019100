/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import UploadIcon from "@mui/icons-material/Upload";

import { useEffect, useState } from "react";
import useAxiosPrivate from "contexts/interceptors/axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useStateContext } from "contexts/ContextProvider";
import { enqueueSnackbar } from "notistack";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#f0f0f0",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function UpdateFileModal({
  open,
  handleClose,
  loadingState,
  selectedData,
  updateTableFunction,
}) {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [file, setFile] = useState();
  const [disabled, setDisabled] = useState(false);
  const [rowData, setRowData] = useState(false);

  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to update this file document?"
    );

    if (confirmed) {
      setLoading(true);
      setError("");

      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileId", rowData.fileId);
      formData.append(
        "remarks",
        `File Document Updated by ${auth?.firstName} ${auth?.lastName} from ${auth?.unitName}`
      );

      await axiosPrivate
        .put(`/documents/replaceDocument/${rowData.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          enqueueSnackbar(`File Document Updated`, {
            variant: "success",
          });
          updateTableFunction();
          handleClose();
        })
        .catch((err) => {
          setError(err?.response?.data?.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleUpload = async (event) => {
    try {
      setLoading(true);
      const uploadedFile = event.target.files[0];

      const formData = new FormData();
      formData.append("file", uploadedFile);

      axiosPrivate
        .post("/documents/validateDocument", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          setFile(uploadedFile);
          setFileError("");
        })
        .catch((err) => {
          console.log(err);
          setFileError(
            err.response?.data?.message ||
              err.response?.data?.error ||
              "Error: Uploaded file invalid"
          );
          setFile(null);
          event.target.value = null;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setFileError("Error: Invalid PDF file.");
      setFile(null);
      event.target.value = null;
      setLoading(false);
    }
  };

  useEffect(() => {
    setDisabled(Boolean(file));
  }, [file]);

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  useEffect(() => {
    if (selectedData) {
      if (Array.isArray(selectedData) && selectedData.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        setRowData(selectedData[0]);
      } else {
        setRowData(selectedData);
      }
    }
  }, [selectedData]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {loading && (
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box sx={style}>
          <Box>
            <Box // Fixed Header
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#59395c",
                // boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderBottomLeftRadius: "30%",
                borderBottomRightRadius: "200px",
                zIndex: 2,
                px: 4,
                mr: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    color: "#fff",
                    py: 1,
                  }}
                >
                  Update File Document
                </Typography>
                <IconButton
                  onClick={handleClose}
                  sx={{ color: "#fff", p: 0, m: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
              {/* <Divider sx={{ backgroundColor: "gray" }} /> */}
            </Box>
            {error && (
              <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  {error}
                </Typography>
              </Box>
            )}

            {fileError && (
              <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  {fileError}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                m: 4,
              }}
            >
              <Box
                sx={{
                  background: "#fff",
                  border: "solid 1px #b6b6b6",
                  borderRadius: "4px",
                  width: "100%",
                  //   m: 2,
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    mt: 2,
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "gray",
                        mr: 2,
                      }}
                    >
                      Current File:
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: "4px",
                        border: "solid 1px #b6b6b6",
                        width: "100%",
                        // minWidth: "200px",
                        color: selectedData?.fileName ? "black" : "#757575",
                        py: "8px",
                        px: "12px",
                        mr: 2,
                      }}
                    >
                      <Typography>
                        {/* Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industrys
                        standard dummy text ever since the 1500s, when an
                        unknown printer took a galley of type and scrambled it
                        to make a type specimen book. It has survived not only
                        five centuries, but also the leap into electronic
                        typesetting, remaining essentially unchanged. It was
                        popularised in the 1960s with the release of Letraset
                        sheets containing Lorem Ipsum passages, and more
                        recently with desktop publishing software like Aldus
                        PageMaker including versions of Lorem Ipsum */}
                        {rowData?.fileName || "No file before"}
                      </Typography>
                    </Box>
                  </Box>

                  <Divider
                    sx={{
                      backgroundColor: "lightgray",
                      width: "100%",
                      py: 0.1,
                      my: 3,
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "gray",
                        mr: 2,
                      }}
                    >
                      Upload new file:
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: "4px",
                        border: "solid 1px #b6b6b6",
                        width: "25vw",
                        minWidth: "200px",
                        color: file?.name ? "black" : "#757575",
                        py: "8px",
                        px: "12px",
                        mr: 2,
                      }}
                    >
                      <Typography>{file?.name || "No file chosen"}</Typography>
                    </Box>
                    <Button
                      htmlFor={loading ? null : "docUpload"}
                      sx={{
                        backgroundColor: loading ? "#59395c" : "#2f2f2f",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "5px 20px",
                        borderRadius: "5px",
                        "&:hover": {
                          backgroundColor: "#f6e247",
                          color: "#59395c",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <Typography
                        variant="label"
                        component="label"
                        htmlFor={loading ? null : "docUpload"}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          fontSize: "small",
                        }}
                      >
                        <UploadIcon sx={{ mr: 1 }} />
                        CHOOSE FILE
                      </Typography>
                      <input
                        id="docUpload"
                        type="file"
                        name="doc_upload"
                        onChange={handleUpload}
                        style={{ display: "none" }}
                      />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 2,
              zIndex: 10,
              mt: 2,
            }}
          >
            <Button
              disabled={!disabled}
              onClick={() => handleSubmit()}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: disabled ? "#59395c" : "lightgray",
                color: "#fff",
                py: 1,
                px: 2,
                // width: "10vw",
                // minWidth: "100px",
                "&:hover": {
                  backgroundColor: "#f6e247",
                  color: "#59395c",
                  fontWeight: "bold",
                },
              }}
            >
              <SaveIcon
                sx={{
                  mr: 1,
                }}
              />
              Update File
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
